import React, { FC, ReactElement } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import { EventType } from '../types'

export const query = graphql`
  query($uid: hasura_uuid!) {
    db {
      events_by_pk(id: $uid) {
        id
        date
        name
        website
        venue {
          name
          city
          state
          country
          website
        }
        set_time
        set_length
        facebook_event
        is_festival
        start_date
        end_date
        event_tickets {
          id
          link
          label
        }
        headliner_band {
          name
        }
        band {
          name
        }
        published
      }
    }
  }
`

interface EventPageProps {
  data?: {
    db?: {
      events_by_pk?: EventType
    }
  }
}

const EventPage: FC<EventPageProps> = ({
  data,
}: EventPageProps): ReactElement | null => {
  if (!data) {
    return null
  }
  const event = data?.db?.events_by_pk
  if (!event) {
    return null
  }

  return (
    <Layout title={event.name} currentNav="Events">
      <div style={{ margin: '5rem auto', width: '550px' }}>
        <article key={event.id}>
          <p>{event.band ? event.band.name : 'No band set...'}</p>
          <pre>{JSON.stringify(event, null, '  ')}</pre>
        </article>
      </div>
    </Layout>
  )
}

export default EventPage
